import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

import { StoreContext } from "../store/context";

const StyledCanvas = styled.canvas`
  max-width: 100%;
  max-height: 100%;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  max-width: 90%;
  max-height: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const Canvas = () => {
  const { store, dispatch } = useContext(StoreContext);
  const { templateImage, croppedImage } = store;
  const canvasRef = useRef(null);

  useEffect(() => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, 1080, 1080);

    const imageObj1 = new Image();
    const imageObj2 = new Image();
    imageObj1.src = croppedImage;
    imageObj1.onload = () => {
      context.save();
      context.fillStyle = "#FFF";
      context.fillRect(0, 0, 1080, 1080);
      context.globalCompositeOperation = "luminosity";
      context.drawImage(imageObj1, 0, 0, 1080, 1080);
      context.restore();
      imageObj2.src = templateImage;
      imageObj2.onload = () => {
        context.drawImage(imageObj2, 0, 0, 1080, 1080);
        const mergedImg = canvasRef.current.toDataURL("image/png");
        dispatch({ type: "MERGE_IMAGE", payload: mergedImg });
      };
    };
  }, [canvasRef, templateImage, croppedImage, dispatch]);

  return (
    <StyledPaper elevation={2}>
      <StyledCanvas ref={canvasRef} width="1080" height="1080" />
    </StyledPaper>
  );
};

export default Canvas;
