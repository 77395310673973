import React, { useContext } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

import { StoreContext } from "../store/context";
import { uploaderStep, cropperStep, downloadStep } from "../copy";
const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  padding: 1em;
`;
const StyledButton = styled(Button)`
  margin-right: 1em;
`;
const Stepper = () => {
  const { store, dispatch } = useContext(StoreContext);
  const { step, uploadedImage, croppedImage, mergedImage } = store;

  const stepContent = () => {
    switch (step) {
      case 1:
        return uploaderStep;
      case 2:
        return cropperStep;
      case 3:
        return downloadStep;
      default:
        return "";
    }
  };

  const handleClick = (direction) => {
    dispatch({ type: "CHANGE_STEP", payload: direction });
  };

  const handleDisabled = () => {
    const noImage = step === 1 && !uploadedImage;
    const noCrop = step === 2 && !croppedImage;
    const noMerge = step === 3 && !mergedImage;

    if (step >= 3 || noImage || noCrop || noMerge) {
      return true;
    }

    return false;
  };
  return (
    <Container>
      <StyledButton
        variant="contained"
        disabled={step <= 1}
        onClick={() => handleClick(-1)}
        style={{ display: step <= 1 ? "none" : "block" }}
      >
        Previous
      </StyledButton>
      <Typography>
        <strong>Step: {step}/3</strong>, {stepContent()}
      </Typography>
    </Container>
  );
};

export default Stepper;
