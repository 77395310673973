import React, { useReducer, useMemo } from "react";
import TagManager from "react-gtm-module";
import CssBaseline from "@material-ui/core/CssBaseline";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { StoreContextProvider } from "../store/context";
import { reducer, initialState } from "../store/reducer";
import Uploader from "./Uploader";
import Cropper from "./Cropper";
import Preview from "./Preview";
import Stepper from "./Stepper";
import { useEffect } from "react";

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
  }
`;

const StepContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
`;

const StepContent = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  transform: ${({ step }) => step && `translateX(${step * -100 + 100}%)`};
  transition: transform 0.5s;
  padding-bottom: 6rem;
  background-color: #f8804c;
`;

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-KJNFQJS",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  // Avoid the children to re-render everytime <Screen> re-renders
  const contextValue = useMemo(() => {
    return { store, dispatch };
  }, [store, dispatch]);

  const { step } = store;
  return (
    <StoreContextProvider value={contextValue}>
      <CssBaseline />
      <StepContainer>
        <StepContent step={step}>
          {/* Uploader */}
          <Uploader />
          {/* Crop */}
          <Cropper />
          {/* Preview */}
          <Preview />
        </StepContent>
      </StepContainer>
      <Stepper />
      <GlobalStyle />
    </StoreContextProvider>
  );
};

export default App;
