export const initialState = {
  templateImage: './img/template.png',
  uploadedImage: null,
  croppedImage: null,
  mergedImage: null,
  step: 1,
};

export function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'CHANGE_STEP':
      return {
        ...state,
        step: state.step + payload,
      };
    case 'UPLOAD_IMAGE':
      return {
        ...state,
        uploadedImage: payload,
      };
    case 'CROP_IMAGE':
      return {
        ...state,
        croppedImage: payload,
      };
    case 'MERGE_IMAGE':
      return {
        ...state,
        mergedImage: payload,
      };
    default:
      return state;
  }
}
