import React, { useRef, useContext } from "react";
import PropTypes from "prop-types";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { StoreContext } from "../store/context";
import { cropperAction } from "../copy";

const StyledButton = styled(Button)`
  display: block;
  margin: 2em auto;
  background-color: #f8804c;
  &:hover {
    background-color: #f8804c;
  }
`;

const Container = styled.div`
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f8804c;
`;

const PreviewContainer = styled(Paper)`
  position: relative;
  width: 300px;
  height: 300px;
  overflow-y: scroll;
`;

const PreviewBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: center center/contain url("./img/template.png") no-repeat;
  overflow-y: scroll;
`;

const Preview = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: grayscale(100%);
  left: 0%;
  top: 0%;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const PreviewGrid = styled(Grid)`
  @media screen and (max-width: 690px), screen and (max-height: 640px) {
    visibility: hidden;
    position: absolute;
    bottom: -1000px;
  }
`;

const CropperContainer = styled(Paper)`
  width: 75%;
  height: 40vh;
  max-height: 600px;
  margin: 0 auto;
`;

const GridContainer = styled(Grid)`
  margin-top: 5px;
`;

const CropperComponent = () => {
  const { store, dispatch } = useContext(StoreContext);
  const { uploadedImage } = store;
  const cropper = useRef(null);
  const previewRef = useRef(null);

  if (!uploadedImage) return null;

  const crop = () => {
    // image in dataUrl
    if (!cropper.current.getCroppedCanvas()) return;
    const imageURL = cropper.current.getCroppedCanvas().toDataURL();
    dispatch({ type: "CROP_IMAGE", payload: imageURL });
    dispatch({ type: "CHANGE_STEP", payload: 1 });
  };

  return (
    <Container>
      <CropperContainer elevation={2}>
        <Cropper
          ref={cropper}
          src={uploadedImage}
          style={{ height: "100%", width: "100%" }}
          aspectRatio={1 / 1}
          guides={false}
          viewMode={1}
          minCropBoxHeight={50}
          minCropBoxWidth={50}
          preview={previewRef}
        />
      </CropperContainer>
      <GridContainer
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <PreviewGrid item>
          <Typography>Preview:</Typography>
          <PreviewContainer>
            <Preview ref={previewRef} />
            <PreviewBackground></PreviewBackground>
          </PreviewContainer>
        </PreviewGrid>
        <Grid item>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => crop()}
          >
            {cropperAction}
          </StyledButton>
        </Grid>
      </GridContainer>
    </Container>
  );
};

CropperComponent.defaultProps = {
  uploadedImage: null,
};

CropperComponent.propTypes = {
  uploadedImage: PropTypes.string,
};

export default CropperComponent;
