import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import { Button } from "@material-ui/core";
import styled from "styled-components";

import { StoreContext } from "../store/context";
import { uploaderAction } from "../copy";

const Container = styled.div`
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  padding: 3em;
  height: 100%;
  width: 100%;
  background: #efefef;
  transition: height 0.5s;
  color: #fff;
  background-color: #f8804c;
  &:hover {
    background-color: #f8804c;
  }
`;

const Content = styled.span`
  font-size: 1.25em;
  white-space: break-spaces;
`;

const Uploader = () => {
  const { dispatch } = useContext(StoreContext);
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result;
          var blob = new Blob([binaryStr], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);

          dispatch({ type: "UPLOAD_IMAGE", payload: imageUrl });
          dispatch({ type: "CHANGE_STEP", payload: 1 });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <Container>
      <RootRef rootRef={ref}>
        <StyledButton color="primary" {...rootProps}>
          <input {...getInputProps()} />
          <Content>{uploaderAction}</Content>
        </StyledButton>
      </RootRef>
    </Container>
  );
};

export default Uploader;
