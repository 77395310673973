import React, { useContext } from "react";
import styled from "styled-components";

import { StoreContext } from "../store/context";
import Canvas from "./Canvas";
import { downloadAction } from "../copy";

const Container = styled.div`
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Preview = () => {
  const { store } = useContext(StoreContext);
  const { uploadedImage, croppedImage, mergedImage } = store;

  if (!uploadedImage || !croppedImage) return null;

  const handleDownload = () => {
    const element = document.createElement("a");
    element.setAttribute("href", mergedImage);
    element.setAttribute("download", "we-are-tourism");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Container>
      <Canvas />
      <button
        style={{
          display: "block",
          margin: "2rem auto",
          backgroundColor: "#F8804C",
        }}
        className="downloadButton MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
        onClick={handleDownload}
      >
        {downloadAction}
      </button>
    </Container>
  );
};

export default Preview;
